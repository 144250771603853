import { FormEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';

import { changePassword } from 'services/authv1';

import { Input } from 'components/Input/Input';
import { ModalError, ModalInfo } from 'components/Modal/Modal';

const PasswordReset = (): JSX.Element => {
  const [hash, setHash] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorNewPassword, setErrorNewPassword] = useState('');
  const [errorNewPasswordConfirmation, setErrorNewPasswordConfirmation] =
    useState('');

  const { t } = useTranslation('pages');

  const routeParams = useParams<{ hash: string }>();
  const history = useHistory();

  useEffect(() => {
    if (routeParams.hash) {
      setHash(routeParams.hash);
    } else {
      history.push('/passwordreset');
    }
  }, [history, routeParams.hash]);

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();

    setErrorNewPassword('');
    setErrorNewPasswordConfirmation('');

    if (!newPassword) {
      setErrorNewPassword(t('PasswordReset.validation_new_password'));
      return false;
    }

    if (!newPasswordConfirmation) {
      setErrorNewPasswordConfirmation(
        t('PasswordReset.validation_new_password'),
      );
      return false;
    }

    if (newPassword !== newPasswordConfirmation) {
      setErrorNewPassword(t('PasswordReset.validation_new_password_equality'));
      setErrorNewPasswordConfirmation(
        t('PasswordReset.validation_new_password_equality'),
      );
      return false;
    }

    setLoading(true);

    const { success, status } = await changePassword(
      hash,
      newPassword,
      newPasswordConfirmation,
    );

    setLoading(false);

    if (success) {
      await ModalInfo.fire({
        title: t('PasswordReset.password_reset_title'),
        html: t('PasswordReset.password_reset_message'),
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });

      history.push('/');
    } else {
      if (status === 404) {
        const response = await ModalError.fire({
          title: t('PasswordReset.password_reset_expired_token_error_title'),
          text: t('PasswordReset.password_reset_expired_token_error_message'),
          showCancelButton: false,
          confirmButtonText: t(
            'PasswordReset.password_reset_expired_token_error_button_label',
          ),
        });

        if (response.isConfirmed) {
          history.push('/passwordreset');
        }
      } else {
        await ModalError.fire({
          title: t('PasswordReset.password_reset_error_title'),
          text: t('PasswordReset.password_reset_error_message'),
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      }
    }
  };

  return (
    <form className="tw-p-6" onSubmit={(ev) => handleSubmit(ev)}>
      <div className="tw-mb-6">
        <Input
          type="password"
          label={t('PasswordReset.new_password')}
          starticon="lock"
          name="password"
          value={newPassword}
          error={errorNewPassword !== ''}
          helpertext={errorNewPassword}
          disabled={loading}
          onChange={(ev) => setNewPassword(ev.target.value)}
        />
      </div>

      <div className="tw-mb-6">
        <Input
          type="password"
          label={t('PasswordReset.new_password_confirmation')}
          starticon="lock"
          name="passwordconfirmation"
          value={newPasswordConfirmation}
          error={errorNewPasswordConfirmation !== ''}
          helpertext={errorNewPasswordConfirmation}
          disabled={loading}
          onChange={(ev) => setNewPasswordConfirmation(ev.target.value)}
        />
      </div>

      <button
        type="submit"
        className="tw-btn tw-btn-primary tw-w-full tw-h-12 tw-flex tw-items-center"
        disabled={loading}
      >
        {t('PasswordReset.confirm')}
      </button>

      <div className="tw-mt-4">
        <Link
          to="/"
          className="tw-flex tw-items-center tw-text-gray-500 hover:tw-text-gray-700"
        >
          <span className="tw-i-arrow-left tw-mr-2"></span>
          <span>{t('PasswordReset.back_to_login')}</span>
        </Link>
      </div>
    </form>
  );
};

export { PasswordReset };
