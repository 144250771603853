declare const window: Window &
  typeof globalThis & {
    gtag: any;
  };

type UseGTagProps = {
  pageTitle?: string;
  usePageLocation?: boolean;
  eventAction?: string;
  eventName: string;
  eventCategory: string;
  eventLabel: string;
};

const useGtag = ({
  eventName,
  eventCategory,
  eventLabel,
  pageTitle,
  usePageLocation = false,
  eventAction,
}: UseGTagProps) => {
  const { host, origin, pathname } = window.location;

  if (typeof window.gtag !== 'undefined') {
    return window.gtag('event', eventName, {
      send_page_view: false,
      page_title: pageTitle,
      page_location: usePageLocation === false ? undefined : origin + pathname,
      event_name: eventName,
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
      event_location: host,
    });
  }
  return null;
};

// eslint-disable-next-line import/no-default-export
export default useGtag;
export { useGtag as dispatchGtag };
