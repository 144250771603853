import { FormEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { requestPasswordReset } from 'services/authv1';
import { dispatchGtag } from 'utils/useGtag';

import { Input } from 'components/Input/Input';
import { ModalInfo } from 'components/Modal/Modal';

const PasswordResetRequest = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');

  const { t } = useTranslation('pages');

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();

    dispatchGtag({
      eventName: 'login',
      eventCategory: 'login_forgot_password',
      eventAction: 'click:button',
      eventLabel: 'finish_forgot_password_login',
    });

    setErrorEmail('');

    if (!email) {
      setErrorEmail(t('PasswordResetRequest.validation_email'));
      return false;
    }

    setLoading(true);

    await requestPasswordReset(email);

    setLoading(false);

    await ModalInfo.fire({
      title: t('PasswordResetRequest.password_reset_request_title'),
      html: t('PasswordResetRequest.password_reset_request_message', {
        email: `<strong class="tw-text-accent-dark">${email}</strong>`,
      }),
      showCancelButton: false,
      confirmButtonText: 'Ok',
    });
  };

  return (
    <form className="tw-p-6" onSubmit={(ev) => handleSubmit(ev)}>
      <div className="tw-mb-6">
        <Input
          label={t('PasswordResetRequest.email')}
          starticon="mail"
          name="email"
          value={email}
          error={errorEmail !== ''}
          helpertext={errorEmail}
          disabled={loading}
          onChange={(ev) => setEmail(ev.target.value)}
        />
      </div>

      <button
        type="submit"
        className="tw-btn tw-btn-primary tw-w-full tw-h-12 tw-flex tw-items-center"
        disabled={loading}
      >
        {t('PasswordResetRequest.confirm')}
      </button>

      <div className="tw-mt-4">
        <Link
          to="/"
          className="tw-flex tw-items-center tw-text-gray-500 hover:tw-text-gray-700"
        >
          <span className="tw-i-arrow-left tw-mr-2"></span>
          <span>{t('PasswordResetRequest.back_to_login')}</span>
        </Link>
      </div>
    </form>
  );
};

export { PasswordResetRequest };
