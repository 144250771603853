let RE_PISMO_DOMAINS =
  /^https?:\/\/([a-zA-Z\d_-]+\.){0,}(pismo.io|pismo.cloud)((\/|#|\?).*)?$/i;

if (
  process.env.REACT_APP_BASE_ENV === 'dev' ||
  process.env.REACT_APP_BASE_ENV === 'integration'
) {
  RE_PISMO_DOMAINS =
    /^https?:\/\/([a-zA-Z\d_-]+\.){0,}(localhost|pismo.io|pismolabs.io|pismo.cloud)((\/|#|\?|:).*)?$/i;
}

const isValidPismoUrl = (url: string) => {
  return RE_PISMO_DOMAINS.test(url);
};

export { isValidPismoUrl };
