import { CrossStorage } from '@pismo/sdk-cross-storage';

const crossStorage = CrossStorage({ baseURL: process.env.REACT_APP_BASE_URL });

const decorateData = (data: UserData) => {
  const email = data.email;
  const token = data.token;
  const refreshToken = data.refresh;
  const roles = data.roles || [];
  const tenant = data.tenant;
  const accounts = data.accounts;
  const marketplace = data.marketplace;
  const merchant = data.merchant;
  const isCustomer = accounts && accounts.length > 0;

  const result: CrossStorageData = {
    email,
    token,
    refreshToken,
    roles,
    tenant,
    accounts,
    marketplace,
    merchant,
    isCustomer,
  };

  return result;
};

export const writeCrossStorageData = (data: UserData) => {
  return crossStorage.writeMany([
    {
      key: process.env.REACT_APP_CROSS_STORAGE_AUTH_KEY,
      value: decorateData(data),
    },
    {
      key: process.env.REACT_APP_CROSS_STORAGE_PREF_KEY,
      value: { identification: data.email },
    },
  ]);
};

export const readCrossStorageData = () => {
  return crossStorage.read(process.env.REACT_APP_CROSS_STORAGE_AUTH_KEY);
};

export const clearCrossStorageData = () => {
  return crossStorage.clear();
};
