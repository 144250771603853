import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { readCrossStorageData } from 'services/crossStorage';
import { isValidPismoUrl } from 'utils/isValidPismoUrl';
import { dispatchGtag } from 'utils/useGtag';

import txtByUrl from 'config/applications.json';

import logo from 'assets/img/horizontal-logo.svg';
import loginHeader from 'assets/img/login-ilustration.svg';

import styles from './Main.module.css';

const queryParams = new URLSearchParams(window.location.search);

const returnTo = queryParams.get('returnTo') || 'https://pismo.io';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps): JSX.Element => {
  const [hiddenLoginDescriptionsBox, setHiddenLoginDescriptionsBox] =
    useState(false);

  const { t } = useTranslation('layouts', {
    keyPrefix: `Main.${returnTo}`,
  });

  const hiddenDescriptionLogin = () => {
    const isHaveUrl = Object.keys(txtByUrl.pt).find((res) => {
      return returnTo === res;
    });

    setHiddenLoginDescriptionsBox(
      !queryParams.get('title') &&
        !queryParams.get('subtitle') &&
        !queryParams.get('description') &&
        !isHaveUrl,
    );
  };

  const formatTextLogin = (param) => {
    if (t(param, { nsSeparator: '' }).search('Main.') === -1) {
      return t(param, { nsSeparator: '' });
    }

    if (queryParams.get(param) !== '') {
      return queryParams.get(param);
    }

    return '';
  };

  useEffect(() => {
    (async () => {
      if (isValidPismoUrl(returnTo)) {
        const data = await readCrossStorageData();
        if (Object.keys(data).length > 0) {
          window.location.replace(returnTo);
        }
        hiddenDescriptionLogin();
      }
    })();
  }, []);

  useEffect(() => {
    dispatchGtag({
      pageTitle: 'home_login',
      usePageLocation: false,
      eventName: 'login',
      eventCategory: 'login_access',
      eventAction: 'pageView',
      eventLabel: 'start_access_login',
    });
  }, []);

  if (isValidPismoUrl(returnTo)) {
    return (
      <section className={styles.container}>
        {!hiddenLoginDescriptionsBox && (
          <div className="tw-flex-1">
            <div className={styles.descriptionLogin}>
              <img src={logo} alt="Pismo Logo" />
              <hr className="tw-from-gray-25" />
              <h3 className="tw-h3 tw-font-bold">{formatTextLogin('title')}</h3>
              <h5 className="tw-h5">{formatTextLogin('subtitle')}</h5>
              <p className="tw-body3 tw-text-gray-90">
                {formatTextLogin('description')}
              </p>
            </div>
          </div>
        )}
        <div className="tw-rounded-lg tw-shadows-5 tw-ml-6">
          <img src={loginHeader} alt="Login header" />
          {children}
        </div>
      </section>
    );
  }

  return (
    <section className={styles.container}>
      <div className="tw-w-full tw-p-8 tw-bg-gray-100 tw-rounded-sm">
        <img src={logo} alt="Pismo Logo" />
        <div className="tw-flex tw-w-full tw-mt-10 tw-p-4 tw-bg-alert tw-text-white tw-rounded-sm tw-font-bold">
          Error. Invalid returnTo URL.
        </div>
      </div>
    </section>
  );
};

export { MainLayout };
