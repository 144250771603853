import React from 'react';

import ReactDOM from 'react-dom';

import '@pismo/bolt/dist/css/bolt-css.css';
import 'styles/global.css';

import { MainLayout } from 'layouts/Main/Main';
import { Router } from 'Router';

import { i18n } from 'config/i18next';

const queryParams = new URLSearchParams(window.location.search);

if (queryParams.get('lang')) {
  i18n.changeLanguage(queryParams.get('lang'));
}

ReactDOM.render(
  <React.StrictMode>
    <MainLayout>
      <Router />
    </MainLayout>
  </React.StrictMode>,
  document.getElementById('root'),
);
