import Swal from 'sweetalert2';

const ModalInfo = Swal.mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton:
      'tw-p-3 tw-m-2 tw-text-white tw-uppercase tw-rounded-sm tw-bg-accent hover:tw-bg-accent-light tw-text-sm',
    cancelButton:
      'tw-p-3 tw-m-2 tw-text-white tw-uppercase tw-rounded-sm tw-bg-gray-400 hover:tw-bg-gray-500 tw-text-sm',
  },
});

const ModalError = Swal.mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton:
      'tw-p-3 tw-m-2 tw-text-white tw-uppercase tw-rounded-sm tw-bg-alert hover:tw-bg-alert-dark tw-text-sm',
    cancelButton:
      'tw-p-3 tw-m-2 tw-text-white tw-uppercase tw-rounded-sm tw-bg-gray-400 hover:tw-bg-gray-500 tw-text-sm',
  },
});

export { ModalInfo, ModalError };
