import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type HttpRequest = AxiosRequestConfig;

export type HttpResponse<T> = {
  status: number;
  success: boolean;
  data: T;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const request = async <T = any>(
  data: HttpRequest,
): Promise<HttpResponse<T>> => {
  let response: AxiosResponse = null;
  let success = true;

  try {
    response = await api.request(data);
  } catch (error) {
    response = error.response;
    success = false;
  }

  return {
    status: response.status,
    success: success,
    data: response.data,
  };
};

export { request };
