import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getTranslations } from 'utils/getTranslations';

export enum Language {
  PT = 'pt',
  EN = 'en',
}

const resources = {
  pt: {
    pages: getTranslations('pages', 'pt'),
    layouts: getTranslations('layouts', 'pt'),
  },

  en: {
    pages: getTranslations('pages', 'en'),
    layouts: getTranslations('layouts', 'en'),
  },
};

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  resources,
  lng: Language.EN,
  fallbackLng: Language.EN,
  interpolation: {
    escapeValue: false,
  },
});

export { i18n };
