import React from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Login } from 'pages/Login/Login';
import { PasswordReset } from 'pages/PasswordReset/PasswordReset';
import { PasswordResetRequest } from 'pages/PasswordResetRequest/PasswordResetRequest';

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/passwordreset" component={PasswordResetRequest} exact />
        <Route path="/passwordreset/:hash" component={PasswordReset} exact />
        <Route path="*" exact>
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export { Router };
