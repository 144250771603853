import * as httpClient from './httpClient';

export const validateRecaptchaHash = (
  email: string,
  password: string,
  recaptchaHash: string,
) => {
  return httpClient.request({
    method: 'POST',
    url: '/auth/v2/users/login/check',
    data: {
      email: email,
      password: password,
      hash: recaptchaHash,
    },
  });
};

export const loginWithMFA = (email: string, password: string, code: string) => {
  return httpClient.request<UserData>({
    method: 'POST',
    url: '/auth/v2/users/login',
    data: {
      email,
      password,
      code,
    },
  });
};
