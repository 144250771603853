import { ChangeEvent, useEffect, useMemo, useRef } from 'react';

import { IInput, IInputDataset, Input as BoltInput } from '@pismo/bolt';

type InputEvents = {
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onStartIconClick?: (ev: MouseEvent) => void;
  onEndIconClick?: (ev: MouseEvent) => void;
};

type InputProps = IInputDataset & InputEvents;

const inputEvents: (keyof InputEvents)[] = [
  'onChange',
  'onFocus',
  'onBlur',
  'onStartIconClick',
  'onEndIconClick',
];

const Input = (props: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLDivElement>();
  const inputInstance = useRef<IInput>();

  const dataProps = useMemo(() => {
    const mappedProps = {};

    Object.keys(props).forEach((key) => {
      if (!inputEvents.includes(key as keyof InputEvents)) {
        mappedProps[`data-${key}`] = props[key];
      }
    });

    return mappedProps;
  }, [props]);

  useEffect(() => {
    if (inputRef.current && !inputInstance.current) {
      inputInstance.current = new BoltInput(inputRef.current);
    }

    if (inputInstance.current) {
      inputEvents.forEach((event: any) => {
        if (props[event]) {
          inputInstance.current[event] = props[event];
        }
      });
    }
  }, [props]);

  return <div ref={inputRef} {...dataProps}></div>;
};

export { Input };
