const getTranslations = (
  type: 'layouts' | 'pages' | 'components',
  lang: string,
) => {
  if (!type) {
    throw new Error(
      'Invalid translation type. Type should be one of "layouts" || "pages" || "components".',
    );
  }

  if (!lang) {
    throw new Error('Invalid translation language.');
  }

  let ctx;

  switch (type) {
    case 'layouts':
      ctx = require.context('../layouts', true, /i18n\.(ts|js|json)$/);
      break;

    case 'pages':
      ctx = require.context('../pages', true, /i18n\.(ts|js|json)$/);
      break;

    case 'components':
      ctx = require.context('../components', true, /i18n\.(ts|js|json)$/);
      break;
  }

  const translations = {};

  ctx.keys().forEach((filePath) => {
    const content = ctx(filePath);
    const fileName = filePath
      .replace(/i18n\.(ts|js|json)$/, '')
      .replace(/\W/g, '');

    translations[fileName] = content.i18n ? content.i18n[lang] : content[lang];
  });

  return translations;
};

export { getTranslations };
