import * as httpClient from './httpClient';

export const requestPasswordReset = (email: string) => {
  return httpClient.request({
    method: 'POST',
    url: `/auth/v2/users/email/password/reset`,
    data: { email },
  });
};

export const changePassword = (
  hash: string,
  password: string,
  passwordConfirmation: string,
) => {
  return httpClient.request({
    method: 'PUT',
    url: `/auth/v1/users/password/USER-RESET-PASSWORD-${hash}`,
    data: {
      password,
      passwordConfirmation,
    },
  });
};
